import styled from "styled-components";

export const VitalSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2em;
  height: calc(100vw / 3.7);
  background-color: ${({ theme }) => theme.colors.st37};
  color: ${({ theme }) => theme.colors.st38};
  /* font-family: ${({ theme }) => theme.fonts.family.st17}; */
  font-size: 3.2vw;
  font-family: Helvetica75-bold;
`;
export const VTypoBox = styled.div``;
export const LineBox = styled.div`
  display: flex;
`;

export const VTLogoBox = styled.div``;

export const VTLogoImg = styled.img`
  width: 19vw;
  height: 19vw;
  object-fit: cover;
`;

import styled from "styled-components";


export const IsContainer = styled.div`
  width: 100%;
  height: calc(100vw / 2.5);
  background-color: ${({theme}) => theme.colors.st3};
`

export const IsSection = styled.div`
  
  height: 100%;
  display: flex;
  
`
export const IsProfile = styled.div`
  width:50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ProfileCircle = styled.div`
  width:60%;
  height: 70%;
    background-color: white;
    border-radius: 50%;
`
export const IsTypo = styled.p`
  font-family: HelveticaNeue-Thin;
  line-height: 1.3em;
  font-size: calc(100vw / 37);
`
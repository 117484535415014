import styled from "styled-components";
import Sense1Pro from "../images/Sense1ProImg.png";
import { ContainerTextLogo } from "./Global.styled";

export const IntroSense1ProContainer = styled.div`
  height: calc(100vw / 2);
  background-color: black;
  background-image: url(${Sense1Pro});
  opacity: 0.88;
  background-size: cover;
  background-position: center -18px;
  background-repeat: no-repeat;
  position: relative;
`;
export const Sense1ProLogo = styled(ContainerTextLogo)`
  color: white;
  font-size: 2.7vw;    
  top: 8%;
  left: 5%;
`;

export const Sense1ProTypo = styled.div`
  font-family: HelveticaNeue-Thin;
  text-align: center;
  color: #c3c3c3;
  font-size: 4vw;
  transform: scaleX(0.9349);
  transform: scaleY(1);
`;

import { FlexContainer } from "../../styles/Global.styled";
import styled from "styled-components";
import CotonLogo from "../../icons/CotonLogo.png";
import {Link, NavLink} from "react-router-dom";

import { ReactComponent as BucketSvg } from "../../icons/Group.svg";

const Header = () => {


  function getClassName() {
    return ({isActive}) => {
      return isActive ? 'activeLink' : 'link';
    };
  }

  return (
    <HeaderWrapper id={'header'}>
      <FlexContainer className={'nav'}
        justify={"space-between"}
        align={"center"}
      >
        <HeaderBlock name={"sense1"}>
          <NavLink to={"/sense1"} className={getClassName()}>sense 1</NavLink>
        </HeaderBlock>
        <HeaderBlock name={"sense1pro"}>
          <NavLink to={"/sense1Pro"} className={getClassName()}>sense 1 pro</NavLink>
        </HeaderBlock>
        <HeaderBlock name={"smartO2"}>
          <NavLink to={"/smartO2"} className={getClassName()}>smartO2</NavLink>
        </HeaderBlock>
        <LogoBlock name={"Logo"}>
          <Link to={"/"}>
            <img className={'main-logo'} src={CotonLogo} alt="MainLogo" />
          </Link>
        </LogoBlock>

        <HeaderBlock name={"cotonAi"}>
          <NavLink to={"/contonAi"} className={getClassName()}>coton ai</NavLink>
        </HeaderBlock>
        <HeaderBlock name={"aboutUs"}>
          <NavLink to={"/aboutUs"} className={getClassName()}>about us</NavLink>
        </HeaderBlock>
        <HeaderBlock>
          <BucketSvg   width="1vw"  />
        </HeaderBlock>
      </FlexContainer>
    </HeaderWrapper>
  );
};


export const HeaderWrapper = styled.header`
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding:0em 2em;
`;
export const HeaderBlock = styled.div`
  text-align: center;
  font-size: 0.9999vw;
  padding: 2em 0em;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
    text-underline-offset: 4px;
    color: ${({ theme }) => theme.colors.st14};
  }
  a{
    font-family: Helvetica65-Medium;
  }
  
  a.link{
    text-decoration: none;
    color: inherit;
    text-align: center;
    
  }
  
  a.activeLink{
    text-decoration: underline;
    text-underline-offset: 4px;
    color: ${({ theme }) => theme.colors.st14};
  }
`;

export const LogoBlock = styled(HeaderBlock)`
  font-size: calc(100vw / 40);
  margin-left: 1.8em;
  margin-right: 3.2em;
  padding-top: 0.7em;
  padding-bottom: 0.6em;
  padding-right: 1.5em;
  padding-left: 1.5em;
  .main-logo{
    width: 8.1vw;
    height: 100%;

    object-fit: cover;
    
  }
  
`;


export default Header;

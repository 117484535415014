import {
  AgeCol,
  BuyBtnColumn,
  BuyButton,
  ColorCol,
  ColorItem,
  ColorValue,
  ColumnTitle,
  ColumnValue,
  DescProduct,
  FirstPlan,
  IntroductionWrapper,
  LearnMorePlanTitle,
  Period,
  PIContainer,
  PIContentsWrapper,
  PIimgBox,
  PIimgDetail,
  PIimgItem,
  PIimgListWrapper,
  PIimgTag,
  PIimgTagFull,
  PIimgTagFull2,
  PIimgTagFull3,
  PILogoWrapper,
  PlanCol,
  PlanSection,
  PriceTitle,
  ProductLeftArrow,
  ProductRightArrow,
  QuantityCol,
  SizeCol,
  TargetUsrCol,
  Using,
  WrapperTitle,
} from "../../styles/ProductInfo.styled";
import Sense1 from "../../images/Sense1Pruducts.png";
import item1 from "../../images/sense1_item1.png";
import item2 from "../../images/sense1_item2.png";
import item3 from "../../images/sense1_item3.png";
import item4 from "../../images/sense1_item4.png";
import leftArrow from "../../icons/leftArrow.svg";
import Arrow from "../../icons/Arrow.svg";
const ProductInfo = ({ name }) => {
  return (
    <PIContainer>
      <PILogoWrapper>{name}</PILogoWrapper>
      <PIContentsWrapper>
        <PIimgBox>
          <PIimgDetail src={Sense1} />
          <PIimgListWrapper>
            <ProductLeftArrow src={Arrow} alt="leftArr" />

            <PIimgItem>
              <PIimgTag src={item1} alt="item1" />
            </PIimgItem>

            <PIimgItem>
              <PIimgTagFull src={item2} alt="item2" />
            </PIimgItem>
            <PIimgItem>
              <PIimgTagFull2 src={item3} alt="item3" />
            </PIimgItem>
            <PIimgItem>
              <PIimgTagFull3 src={item4} alt="item4" />
            </PIimgItem>
            {/*<ProductRightArrow src={leftArrow} alt="leftArr" />*/}
            <ProductRightArrow src={Arrow} alt="rightArr" />
          </PIimgListWrapper>
        </PIimgBox>
        {/*-----*/}
        <IntroductionWrapper>
          <WrapperTitle>
            <PriceTitle>$15</PriceTitle>
            <Period>per month</Period>
          </WrapperTitle>
          <DescProduct>
            24/7 monitoring enables quick diagnosis and notification of the
            <br />
            dog's condition Device for early diagnosis and prevention
          </DescProduct>
          <QuantityCol>
            <ColumnTitle>quantity</ColumnTitle>
            <ColumnValue>+ 01 -</ColumnValue>
          </QuantityCol>
          <ColorCol>
            <ColumnTitle>color</ColumnTitle>
            <ColorValue>
              <ColorItem color={"#7F838C"} />
              <ColorItem color={"#BEACA5"} />
            </ColorValue>
          </ColorCol>
          <SizeCol>
            <ColumnTitle>size</ColumnTitle>
            <ColumnValue>22 x 15 x 300 (maximum) mm</ColumnValue>
          </SizeCol>
          <PlanCol>
            <ColumnTitle>plan</ColumnTitle>
            <PlanSection>
              <FirstPlan>
                $15 per month{" "}
                <LearnMorePlanTitle>
                  learn more about subscribe plan >
                </LearnMorePlanTitle>{" "}
              </FirstPlan>

              <ColumnValue> $200 per year</ColumnValue>
            </PlanSection>
          </PlanCol>
          <AgeCol>all age</AgeCol>
          <TargetUsrCol>for vet and Indivisual consumer</TargetUsrCol>
          <Using>everyday use</Using>

          <BuyBtnColumn>
            <BuyButton>buy now</BuyButton>
            <BuyButton>cart</BuyButton>
          </BuyBtnColumn>
        </IntroductionWrapper>
      </PIContentsWrapper>
    </PIContainer>
  );
};
export default ProductInfo;

import styled from "styled-components";

export const BackgroundContainer = styled.div`
  width: 100%;
  height: ${(props) => `${props.height}`};
  background-image: ${(props) => `url(${props.bg})`};
  background-size: ${(props) => `${props.size}`};
  background-repeat: no-repeat;
  background-position: ${(props) => `${props.position}`};
  background-color: ${(props) => `${props.color}`};
`
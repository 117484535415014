import {
  LearnMoreLink,
  ProductsContainer,
  Sense1ImageBg,
  Title,
  SmartO2ImageBg,
  S1TitleContainer,
  S2TitleContainer,
} from "../styles/Products.styled";
import { FlexContainer } from "../styles/Global.styled";

const Products = () => {
  return (
    <ProductsContainer>
      <FlexContainer height={"100%"}>
        <Sense1ImageBg>
          <S1TitleContainer>
            <Title>Sense 1</Title>
            <LearnMoreLink>Learn more</LearnMoreLink>
          </S1TitleContainer>
        </Sense1ImageBg>
        <SmartO2ImageBg>
          <S2TitleContainer>
            <Title>Smart O2</Title>
            <LearnMoreLink>Learn more</LearnMoreLink>
          </S2TitleContainer>
        </SmartO2ImageBg>
      </FlexContainer>
    </ProductsContainer>
  );
};

export default Products;

import styled from "styled-components";
import { BackgroundContainer } from "./Background.styled";

export const SpecContainer = styled.div`
  font-family: Helvetica75-Bold;
  display: flex;
  flex-direction: column;
  padding: 6em 4em;
`;
export const SpecWrapper = styled.div`
  margin-bottom: 3.3em;
  display: flex;
  width: 100%;
  gap:1em;

`;
export const SpecBox = styled.div`
  display: flex;
  gap: 3em;
`;
export const SpMainTitle = styled.div`
  width: 20%;
  font-size: 2vw;
  flex-shrink: 0;
`;
export const SpMainContentWrapper = styled.div`
  width: 100%;
`
export const SpecTable = styled.div`
  display: flex;
  flex-grow: 1;
  border-top: 1px solid black;
`;

export const SPTtitle = styled.div`
  padding: 0.5em 0;
  width: 30%;
  flex-shrink: 0;
`;
export const SPTColumns = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const SPTColLine = styled.div`
  display: flex;
  border-bottom: ${({ lastChild }) =>
    !lastChild ? "1px solid black" : undefined};

`;

export const SPTCtitle = styled.div`
  width: 50%;
  flex-shrink: 0;
  padding: 0.5em 0;
`;
export const SPTCvalWrapper = styled.div`
display: flex;
  width: 100%;
  flex-direction: column;

 
`
export const SPTCval = styled.div`

  display: flex;


  padding: ${({ pStyle }) =>
          pStyle ? 'none' : '0.5em 0'};
  gap:0.5em;
  border-bottom: ${({ line }) =>
          line ? "1px solid black" : undefined};
`;

export const SPTMultipleCval = styled(SPTCval)`
  border-bottom: 1px solid black;
  padding: 0.5em 0;
`
export const SPTMultipleColLine = styled(SPTColLine)`
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap:1rem;

`

import styled from "styled-components";
import SmartO2 from "../images/SmartO2Img.png";
import Sense1 from "../images/Sense1IntroImg.png";

export const ProductsContainer = styled.section`
  width: 100%;
  height: calc(100vw / 2.1);
`;
export const ImageBgContainer = styled.div`
  width: 50%;
  height: 100%;

  background-repeat: no-repeat;
  position: relative;
`;
export const SmartO2ImageBg = styled(ImageBgContainer)`
  background-color: ${({ theme }) => theme.colors.st4};
  background-image: url(${SmartO2});
  background-size: 135.57% 75.48%;
  background-position: 0em 6em;

  /*transform: matrix(0.6459,0,0,0.6452,0,0);*/
`;
export const Sense1ImageBg = styled(ImageBgContainer)`
  background-size: 150.57% 90.48%;
  background-position: -6em center;
  background-color: ${({ theme }) => theme.colors.st3};
  background-image: url(${Sense1});
`;
export const S1TitleContainer = styled.div`
  position: absolute;
  top: 8%;
  left: 6%;
`;
export const S2TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4em;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Title = styled.div`
  font-family: Helvetica65-Medium;
  color: black;
  font-size: 2.7vw;
  transform: scaleX(1);
  transform: scaleY(1);
`;

export const LearnMoreLink = styled.a`
  font-family: Helvetica65-Medium;
  font-size: calc(100vw / 73.1);
  color: ${({ theme }) => theme.colors.st14};
`;

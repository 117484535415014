import styled from "styled-components";

export const TypographyContainer = styled.div`
  margin-top: 0.6rem;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.st3};
  font-family: Helvetica75-Bold;
  position: relative;
`;

export const TypographyMsg = styled.p`
  line-height: 2.2em;
  font-size: 1.4vw;
  /* font-family: ${({ theme }) => theme.fonts.family.st17}; */
`;

export const SourceTitle = styled.p`
  position: absolute;
  top: 23%;
  left: 65%;
  font-family: Helvetica65-Medium;
  font-size: 0.8vw;
`;

import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.st26};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FSection = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vw / 13.1);
`;

export const FBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.6em;
  margin-right: 1.6em;
  font-size: calc(100vw / 40);
`;
export const FTypo = styled.div`
  font-size: 0.4em;
  font-family: ${({ theme }) => theme.fonts.family.st7};
`;

export const IconSection = styled(FSection)``;
export const CopyrightSection = styled(FSection)``;

export const IconBox = styled(FBox)`
  gap: 1em;
  font-size: 28px;
`;
export const CopyBox = styled(FBox)`
  width: 100%;
  justify-content: space-between;
`;
export const FLogo = styled.img`
  height: 50px;
  object-fit: cover;
`;

export const TypoBox = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
`;

export const ContactSection = styled.p`
  margin: 5px, 0;
`;
export const ContactTypo = styled(FTypo)``;

export const CopyTypo = styled(FTypo)``;
export const SitemapTypo = styled(FTypo)`
  width: fit-content;
  display: flex;
  align-items: center;
  p::before{
    content: "";
    padding-left:0.7em;
  }
  p:not(:last-of-type) {

    &::after{
      display: inline-block;
      content: "";
      border-right: 1px solid black;
      padding-right:1em;
      height:0.8em;
    }
  }
`;
export const TypoBarrel = styled.div`
  &:before {
    content: "";
    width: 20em;
  }
`;

import {IntroSense1ProContainer, Sense1ProLogo, Sense1ProTypo} from "../styles/IntroSense1Pro.styled";
import {FlexContainer} from "../styles/Global.styled";


const IntroSense1Pro = () =>{

    return(
        <IntroSense1ProContainer>
            <FlexContainer height={"100%"} justify={"center"} align={"center"}>
                <Sense1ProLogo>Sense 1 Pro</Sense1ProLogo>
                <Sense1ProTypo>
                    Professional-grade veterinary pet biosignals
                </Sense1ProTypo>
            </FlexContainer>
        </IntroSense1ProContainer>
    )
}

export default IntroSense1Pro;

import {ColorItem} from "../styles/ProductInfo.styled";

export const Sense1Data = [
    {
        Title: 'Key Specifications',
        Processor: ['Cortex-M4, 32 bit with FPU'],
        RAM: ['256KB'],
        ROM: ['1MB (internal), 4GB (external)'],
        Connectivity: ['Bluetooth®5, 2 Mbps']
    },

    {
        Title: 'Sensors',
        BCG: ['Piezofilm, arrhythmia detection'],
        '6-AXIS': ['ICM-42605'],
        MEMS: ['ICM-40619'],
        Temperature: ['CMOSense® sensor chip (typical accuracy of 0.1˚C)'],
        Humidity: ['CMOSense® sensor chip (typical accuracy of 1.5% RH)']
    },
    {
        Title: 'Others',
        'Rate input factor': ['5V/0.8A'],
        'Battery': ['Li-Polymer, 3.8V/460mAh (typical)'],
        'Insert': ['type USB type-C'],
        'colors': [<><ColorItem color={'#7F838C'}/><ColorItem color={'#BEACA5'} /></>]
    }
]
export const SmartO2Data = [
    {
        Title: 'Key Specifications',
        'Blometric sensor hub': ['MAX32664'],
        'Optical pulse oximeter': ['MAX86140'],
        Function: ['Pulse heart rate', 'SpO2'],
        Connection: ['Once plugged into Sense1, it will automatically use the Sense 1 CPU to store and send data.'],
        'Insert type': ['USB type-c'],
        Power: ['Sense 1 battery (Li-Polymer, 3.8V/460mAh)'],
        'Attachment area': ['Ear, tongue']
    }
]
import styled from "styled-components";
import { ContainerTextLogo, FirstChildContainer } from "./Global.styled";

export const PIContainer = styled(FirstChildContainer)`
  background-color: ${({ theme }) => theme.colors.st3};
  padding: 2em 5em;
  height: fit-content;
`;

export const PILogoWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.st7};
  font-size: 5.1vw;
`;
export const PIContentsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  gap:1em;
`;

/*메인 로고 리팩토링*/
export const PIimgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  width: 65%;
  height: 100%;
  overflow: hidden;
`;
export const PIimgDetail = styled.img`
  width: 500px;
  height: 340px;
  transform: scale(1.5);
  object-fit: cover;
  float: left;
`;
export const PIimgListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;
export const PIimgItem = styled.div`
  width: 14%;
  height: 6em;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;
export const PIimgTag = styled.img`
  position: absolute;
  top: -2%;
  left: -2%;
  width: 140%;
  height: 100%;
`;
export const PIimgTagFull = styled.img`
  position: absolute;
  top: 21%;
  left: 0px;
  width: 100%;
  height: 60%;
`;
export const PIimgTagFull2 = styled.img`
  position: absolute;
  top: -4%;
  left: -50%;
  width: 200%;
  height: 110%;
`;
export const PIimgTagFull3 = styled.img`
  position: absolute;
  top: 0px;
  left: -46%;
  width: 207%;
  height: 110%;
`;

export const ProductLeftArrow = styled.img`
  width: 13%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);

  object-fit: cover;
`;

export const ProductRightArrow = styled.img`
  width: 13%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%) rotate(-180deg);

  object-fit: cover;
`;
export const WrapperTitle = styled.div`
  display: flex;
  gap: 1em;
`;
export const PriceTitle = styled.div`
  font-size: 48px;
  font-family: Helvetica75-Bold;
`;
export const Period = styled.div`
  flex-grow: 1;
  font-family: Helvetica75-Bold;
`;

export const IntroductionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  font-family: Helvetica75-Bold;
  position: relative;
`;

export const NormalColumn = styled.div`
  border-bottom: 1px solid black;
  padding: 0.5em 0;
`;

export const FlexColumn = styled(NormalColumn)`
  display: flex;
  gap: 0.5em;
`;
export const DescProduct = styled.div`
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding-top: 0.5em;
  padding-bottom: 4em;
`;
export const ColumnTitle = styled.div`

  width:65px;
  flex-shrink: 0;

`;
export const ColumnValue = styled.div`
  display: flex;
`;
export const QuantityCol = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: center;
`;
export const ColorCol = styled(FlexColumn)`
  justify-content: flex-start;
  padding-bottom: 1em;
`;
export const ColorValue = styled(ColumnValue)`
  gap: 0.5em;
`;
export const ColorItem = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ color }) => color};
  border: none;
`;
export const SizeCol = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: center;
`;
export const PlanCol = styled(FlexColumn)`
  justify-content: flex-start;
  position: relative;
`;
export const PlanSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width:100%;

`;
export const FirstPlan = styled(ColumnValue)`
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
`;

export const LearnMorePlanTitle = styled.p`
  color: ${({ theme }) => theme.colors.st14};
  text-align: right;
 
`;

export const AgeCol = styled(NormalColumn)``;
export const TargetUsrCol = styled(NormalColumn)``;
export const Using = styled(NormalColumn)``;

export const BuyBtnColumn = styled(NormalColumn)`
  border: none;
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
  gap: 4em;
`;
export const BuyButton = styled.button`
  color: ${({ theme }) => theme.colors.st2};
  font-family: Helvetica75-Bold;
  border: none;
  background-color: transparent;
  font-size: 16px;
`;

import {ThemeProvider} from "styled-components";
import {theme} from "./utils/theme";
import {Container} from "./styles/Global.styled";
import Header from "./components/common/Header";
import Footer from "./components/Footer";
import Sense1Page from "./pages/Sense1Page";
import SmartO2Page from "./pages/SmartO2Page";
import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import {useEffect, useState} from "react";

function App() {

    const [headerSize,setHeaderSize] = useState(0)
    useEffect(() => {
        const header = document.getElementById('header');

        setHeaderSize(header.offsetHeight)
    }, [headerSize]);

    return (
        <ThemeProvider theme={theme}>
                <Container headerSize={headerSize}>
                    <Header />
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/sense1" element={<Sense1Page />} />
                        <Route path="/smartO2" element={<SmartO2Page />} />
                    </Routes>
                    <Footer />
                </Container>
        </ThemeProvider>
    );
}

export default App;

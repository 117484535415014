import {LineBox, VitalSection, VTLogoBox, VTLogoImg, VTypoBox} from "../styles/VitalTypography.styled";
import CotonAiSVG from '../icons/cotonsLogo.svg'

const VitalTypography = () => {

    return (
        <VitalSection>
            <VTLogoBox>
                <VTLogoImg src={CotonAiSVG} alt={"CotonLogo"}/>
            </VTLogoBox>
            <LineBox>
                <svg style={{width:'30vw'}}>
                    <line
                        x1="0"
                        y1="50%"
                        x2="100%"
                        y2="50%"
                        stroke="#1D1B1C"
                        strokeWidth="0.9036"
                        strokeMiterlimit="10"
                    />
                </svg>
            </LineBox>
            <VTypoBox>
                vital<br/>
                clinical<br/>
                behavioral
            </VTypoBox>
        </VitalSection>


    )
}

export default VitalTypography;
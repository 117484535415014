export const theme = {

    colors: {
        st0: '#E4D1C7',
        st1: '#FAF5ED',
        st2: '#FFFFFF',
        st3: '#DFE0E1',
        st4: '#FBF5EE',
        st5: '#ECECEC',
        st6: '#DEC7B7',

        st14: '#FF5713',
        st26:'#666666',


        st37: '#D7CDC8',
        st38:'#231F20'
    },
    fonts: {
        family: {
            st7: 'Helvetica65-Medium',
            st17: 'Helvetica75-Bold',
            st31: 'MyriadPro-Regular'
        },
        weight: {
            regular: 400,
            medium: 500,
            bold: 700
        }
    },
    breakpoints: {
        mobile: '720px'
    }

}
export const Nbsp=() => ('')


export const GatewayData = [
    {
        Title: 'Key Specifications',
        Processor: ['ARM Cortex-A53, QUAD-CORE'],
        RAM: ['2GB'],
        ROM: ['16GB'],
        Connectivity: ['Dual-Band 802.11a/b/g/n, Bluetooth4.1']
    },
    {
        Title: 'Others',
        'Rate input factor': ['5V/02.5A'],
        'Insert type':['USB type-C'],
        Adaptor:['insert: 100-240V/0.7A ','output: 9V/2.77A or 5V/3A']
    }
]
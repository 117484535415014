import {IsContainer, IsProfile, IsSection, IsTypo, ProfileCircle} from "../styles/IntroSection.styled";


const IntroSection = () =>{

    return(
        <IsContainer>
            <IsSection>
                <IsProfile>
                    <ProfileCircle />
                </IsProfile>
                <IsProfile>
                    <IsTypo>
                        " Professional-grade veterinary pet<br/>
                        biosignals at home could be coming<br/>
                        in the form of a smart collar, which<br/>
                        Cotons AI claims can extend the life<br/>
                        of a dog by offering precis"
                    </IsTypo>
                </IsProfile>
            </IsSection>

        </IsContainer>


    )
}
export default IntroSection;
import styled from "styled-components";
import Bg from "../images/IntroSense1.png";

export const Container = styled.div`
  margin:auto;
  height: 100vh;
  padding-top: ${({headerSize})=>headerSize}px;

`

export const FirstChildContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  font-family: Helvetica65-Medium;
  
  position: relative;

  // @media screen and (max-width: ${({theme}) => theme.breakpoints.mobile}) {
  //   height: calc(100vw / 1.3);
  // }
`
export const ContainerTextLogo = styled.div`
  font-family: ${({theme}) => theme.fonts.family.st7};
  position: absolute;
  transform:scaleX(1);
  transform:scaleY(1);
`


export const PaddingContainer = styled.div`
  padding-top: ${({top})=>top};
  padding-bottom: ${({bottom})=>bottom};
  padding-left: ${({left})=>left};
  padding-right: ${({right})=>right};
  height:${({height})=>height};
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${({justify})=>justify};
  align-items: ${({align})=>align};
  gap: ${({gap})=>gap};
  flex-direction: ${({direction})=>direction};
  
  height:${({height})=>height};
  & > div {
    flex:${({fullWidthChild})=> fullWidthChild && 1}
    /*flex-grow:1 , flex-shrink:1, flex-basis:0%;
      -> flex-basis가 0이므로 점유 크기를 0으로 만든 후 
        화면 비율에 따라 유연하게 늘어나거나 줄어들 수 있음을 만드는 속성*/
  }
  `
import {
    SpecContainer,
    SpecTable,
    SpMainTitle,
    SpecBox,
    SPTtitle,
    SPTColumns,
    SPTCtitle,
    SPTCval,
    SPTColLine,
    SpecWrapper, SpMainContentWrapper, SPTCvalWrapper
} from "../../styles/Specification.styled";

const Specification = ({Spec,line}) => {
        return (
            <SpecContainer>
                {Spec.map((Section, idx) => {
                    return (
                        <SpecWrapper key={Section.MainTitle}>
                            <SpMainTitle>
                                {Section.MainTitle}
                            </SpMainTitle>
                            <SpMainContentWrapper>
                                {Section.SpecData.map((Column, idx) => {
                                    const {Title: ColumnTitle, ...data} = Column;
                                    return (
                                        <SpecBox key={idx}>
                                            <SpecTable>
                                                <SPTtitle>  {ColumnTitle}</SPTtitle>
                                                <SPTColumns>
                                                    {Object.entries(data).map((col, index, origin) => {

                                                        let [attr, attrValue] = col;
                                                        return (
                                                            <SPTColLine key={attr} lastChild={origin.length - 1 === index}>
                                                                <SPTCtitle>{attr}</SPTCtitle>
                                                                <SPTCvalWrapper>
                                                                    {attrValue.map((val,idx,origin)=>{
                                                                        console.log(Section.MainTitle, attr);
                                                                        return <SPTCval pStyle={Section.MainTitle==='Gateway'&&attr==='Adaptor'&&origin.length-1===idx} line={line&&origin.length-1!==idx} key={val}>{val}</SPTCval>
                                                                    })}
                                                                </SPTCvalWrapper>


                                                            </SPTColLine>
                                                        );
                                                    })}
                                                </SPTColumns>

                                            </SpecTable>

                                        </SpecBox>
                                    )
                                })}
                            </SpMainContentWrapper>

                        </SpecWrapper>
                    )
                })}
            </SpecContainer>)
            ;
    }
;

export default Specification;


import styled from "styled-components";
import image from "../images/Sense1.png";

import {FlexContainer} from "../styles/Global.styled";

import {FaApple} from "react-icons/fa";
import {RiGooglePlayFill} from "react-icons/ri";

const Download = () =>{

    return(<DLContainer>
        <FlexContainer height={"100%"}>
            <DLimg>
                <DLimgBox />
            </DLimg>
            <DLlink>
                <DLbtn>
                    <Logo><FaApple /></Logo>
                    <LinkBox>
                        <LHeadTitle>Download on the</LHeadTitle>
                        <LMainTitle>Apple Store</LMainTitle>
                    </LinkBox>
                </DLbtn>
                <DLbtn>
                    <Logo><RiGooglePlayFill /></Logo>
                    <LinkBox>
                        <LHeadTitle>GET IN ON</LHeadTitle>
                        <LMainTitle>Google Play</LMainTitle>
                    </LinkBox>
                </DLbtn>

            </DLlink>



        </FlexContainer>

    </DLContainer>)
}

export const DLContainer = styled.div`
  width: 100%;
  height: calc(100vw / 3.5);
  background-color: ${({ theme }) => theme.colors.st3};
`;

export const DLsection = styled.div`
  height: 100%;
  display: flex;
`;

export const DLimg = styled(DLsection)`
  width: 50%;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3em;
`;
export const DLlink = styled(DLsection)`
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 2.5em;
`;
export const DLimgBox = styled.div`
  margin-top: 2.1em;
  width: 85%;
  height: 80%;
  background-image: url(${image});
  background-size: cover;
`;

export const DLbtn = styled.button`
  margin-top: 4.1em;
  border: none;
  border-radius: 4px;
  background-color: black;
  color: ${({ theme }) => theme.colors.st2};
  display: flex;
  width: fit-content;
  height: auto;
  align-items: center;
  padding-top: 0.5rem;
  padding-right: 1.9rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  gap: 0.2rem;
  text-align: left;
`;
export const Logo = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-size: 2vw;
  //30 18 10
`;
export const LinkBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 2;
  width: fit-content;
`;
export const LHeadTitle = styled.div`
  font-family: HelveticaNeue-Thin;
  font-size: 0.6666vw;
`;
export const LMainTitle = styled.div`
  font-family: Helvetica65-Medium;
  font-size: 1.2vw;
`;




export default Download;
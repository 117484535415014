
import PlusIcon from "../icons/Plus.svg";
import styled from "styled-components";

const Question = () => {
    return (
        <QContainer>
            <QSection>
                <QTitle>frequently asked questions</QTitle>
                <QAccordion>
                    <QABar>
                        <QATitle>How do i find my band size?</QATitle>
                        <QAPlusBtn src={PlusIcon} alt="PlusIcon"/>
                    </QABar>
                    <QABar>
                        <QATitle>How do i find my band size?</QATitle>
                        <QAPlusBtn src={PlusIcon} alt="PlusIcon"/>
                    </QABar>
                    <QABar>
                        <QATitle>How do i find my band size?</QATitle>
                        <QAPlusBtn src={PlusIcon} alt="PlusIcon"/>

                    </QABar>
                    <QABar>
                        <QATitle>How do i find my band size?</QATitle>
                        <QAPlusBtn src={PlusIcon} alt="PlusIcon"/>

                    </QABar>

                </QAccordion>


            </QSection>
        </QContainer>

    );
}


export const QContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica75-Bold;
`;
export const QSection = styled.section`
  margin: 5.2vw 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
export const QTitle = styled.div`
  font-family: Helvetica75-Bold;
  font-size: calc(100vw / 37);
`;

export const QAccordion = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
`;
export const QABar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
`;

export const QATitle = styled.div`
  display: inline-block;
  font-family: Helvetica75-Bold;
  font-size: calc(100vw / 70);
  

  @media screen and (min-width: 1100px) {  
    font-size: 16px;
    
  }
`;


export const QAPlusBtn = styled.img`
  width: 2.7vw;
  height: 2.7vw;
  margin:0.6rem 0;
  object-fit: cover;
`;



export default Question;
import ProductInfo from "../components/common/ProductInfo";
import Specification from "../components/common/Specification";
import {SmartO2Data} from "../data/SmartO2Data";
import {BackgroundContainer} from "../styles/Background.styled";
import SmartO2Bg1 from '../images/Sense1Pruducts.png'
import SO2bg2 from '../images/SO2bg2.png';
import SO2bg3 from '../images/SO2bg3.png';
import {LeftContainer, RightContainer, SO2BgContainer} from "../styles/SmartO2Page.styled";
import {Sense1Data} from "../data/Sense1Data";
import {GatewayData} from "../data/GatewayData";

const SmartO2Page = () => {

    const Spec = [
        {
            MainTitle: 'SmartO2',
            SpecData: SmartO2Data
        },


    ]
    return (
        <>
            <ProductInfo name={'Smart O2'}/>
            <SO2BgContainer height={'calc(100vw / 2.64)'}>
                <RightContainer>
                    <BackgroundContainer height={'100%'} size={'100% 100%'} bg={SmartO2Bg1}/>
                </RightContainer>
                <LeftContainer>
                    <BackgroundContainer height={'50%'} size={'cover'} bg={SO2bg2} color={'#FBF5EE'}/>
                    <BackgroundContainer height={'50%'} size={'220% 150%'} position={'38%'} bg={SO2bg3} color={'#DEC7B7'}/>
                </LeftContainer>
            </SO2BgContainer>
            <Specification Spec={Spec} line/>
        </>
    )
}
export default SmartO2Page;
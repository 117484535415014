
import styled from "styled-components";
import Bg from "../images/IntroSense1.png";
import {FirstChildContainer} from "../styles/Global.styled";


const IntroSense1 = () => {
    return (
        <S1Container>
            <S1TextBox>
                <HeadTitle>Introducing the</HeadTitle>
                <MainTitle>Sense 1</MainTitle>
                <LearnTitle>Learn more</LearnTitle>
            </S1TextBox>
        </S1Container>
    )
}


/*first child*/
export const S1Container = styled(FirstChildContainer)`
  background-image: url(${Bg});
  background-size: cover;
  background-position: center;
`;

export const S1TextBox = styled.div`
  display: inline;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(175%, -43%);
  
`;

export const HeadTitle = styled.div`
  font-size: 1.68350vw;
  margin-bottom: 0.7em;


`;

export const MainTitle = styled.div`
  font-size: 4.16666vw;
  margin-bottom: 0.5em;
  
`;

export const LearnTitle = styled.div`
  font-size: 1.36798vw;
  color: ${({ theme }) => theme.colors.st2};
  
`;




export default IntroSense1;
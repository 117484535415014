import styled from "styled-components";
import {BackgroundContainer} from "./Background.styled";
export const SO2BgContainer = styled(BackgroundContainer)`
    
    display: flex;
    
`
export const RightContainer = styled(BackgroundContainer)`

  background-color: ${({theme}) => theme.colors.st4};
  width:65%;
  margin-right:1em;
`
export const LeftContainer = styled(BackgroundContainer)`
  width:35%;
`
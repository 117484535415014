import {
  ContactSection,
  ContactTypo,
  CopyBox,
  CopyrightSection,
  CopyTypo,
  FLogo,
  FooterContainer,
  IconBox,
  IconSection,
  SitemapTypo,
  TypoBox,
} from "../styles/Footer.styled";
import { FaFacebookF, FaTwitter, FaPinterestP } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { SiTumblr } from "react-icons/si";
import CotonLogo from "../icons/CotonLogo.png";
const Footer = () => {
  return (
    <FooterContainer>
      <IconSection>
        <IconBox>
          <FaFacebookF />
          <FaTwitter />
          <RiInstagramFill />
          <FaPinterestP />
          <SiTumblr />
        </IconBox>
      </IconSection>
      <CopyrightSection>
        <CopyBox>
          <FLogo src={CotonLogo} alt="FooterLogo" />
          <TypoBox>
            <ContactSection>
              <ContactTypo>Contact US</ContactTypo>
              <ContactTypo>Caresix inc. | #9304, 516-ro 2870, jeju</ContactTypo>
              <ContactTypo>T 82+64-756-7333</ContactTypo>
              <ContactTypo>E caresix@caresix.ai</ContactTypo> 
            </ContactSection>
            <CopyTypo>Copyight © 2022 Cotons. All rights reserved by Caresix inc.</CopyTypo>
            <SitemapTypo>
              <p>Sitemap</p>
              <p>Terms and Conditions</p>
              <p>Privacy Policies</p>
              <p>Sales</p>
              <p>Legal</p>
            </SitemapTypo>
          </TypoBox>
        </CopyBox>
      </CopyrightSection>
    </FooterContainer>
  );
};

export default Footer;

import ProductInfo from "../components/common/ProductInfo";
import bg1 from "../images/Background1.png"
import bg2 from "../images/Background2.png"
import bg3_1 from "../images/Background3-1.png";
import bg3_2 from "../images/Background3-2.png";
import bg4 from "../images/Background4.png";
import {BackgroundContainer} from "../styles/Background.styled";
import Specification from "../components/common/Specification";
import {Sense1Data} from "../data/Sense1Data";
import {GatewayData} from "../data/GatewayData";




const Sense1Page = () => {

    const Spec = [
        {
            MainTitle: 'Sense1',
            SpecData: Sense1Data
        },
        {
            MainTitle: 'Gateway',
            SpecData: GatewayData
        }

    ]
    return (
        <>
            <ProductInfo name={'Sense 1'}/>
            <BackgroundContainer height={'calc(100vw / 1.52)'} size={'100% 100%'} bg={bg1}/>
            <BackgroundContainer height={'calc(100vw / 1.82)'} size={'100% 100%'} bg={bg2}/>
            <BackgroundContainer height={'calc(100vw / 2.64)'} size={'79% 120%'} bg={bg3_1}/>
            <BackgroundContainer height={'calc(100vw / 3.64)'} size={'60% 150%'} position={'right bottom'} bg={bg3_2}/>
            <BackgroundContainer height={'calc(100vw / 2.64)'} size={'100% 100%'} bg={bg4} color={'#E4D1C7'}/>
            <Specification Spec={Spec}/>
        </>
    )
}
export default Sense1Page;
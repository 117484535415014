import IntroSense1 from "../components/IntroSense1";
import Typography from "../components/Typography";
import Products from "../components/Products";
import VitalTypography from "../components/VitalTypography";
import IntroSense1Pro from "../components/IntroSense1Pro";
import Download from "../components/Download";
import IntroSection from "../components/IntroSection";
import Question from "../components/Question";


const MainPage = () =>{
    return(

        <>
            <IntroSense1 />
            <Typography />
            <Products />
            <VitalTypography />
            <IntroSense1Pro />
            <Download />
            <IntroSection />
            <Question />
        </>
    )
}
export default MainPage;
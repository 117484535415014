import { PaddingContainer } from "../styles/Global.styled";
import {
  SourceTitle,
  TypographyContainer,
  TypographyMsg,
} from "../styles/Typography.styled";

const Typography = () => {
  return (
    <TypographyContainer>
      <PaddingContainer
        top={"4%"}
        bottom={"5%"}
        left={"5%"}
        right={"40%"}
        height={"100%"}
      >
        <TypographyMsg>
          "Professional-grade veterinary pet biosignals at home could be <br />
          coming in the form of a smart collar, which Cotons AI claims can
          exextend <br />
          the life of a dog by offering precise health <br />
          metrics and transverring them to your phone."
        </TypographyMsg>
      </PaddingContainer>
      <SourceTitle>Nikolas Lanum</SourceTitle>
    </TypographyContainer>
  );
};

export default Typography;
